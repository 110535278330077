<script>
import $ from 'jquery'
import UserForm from "./user/UserForm";
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import axios from "axios";
import Link from "./utils/Link";
import ui from "./utils/ui";

export default {
  name: "MainUserProfile",
  components: {UserForm},
  mixins: [UserForm],
  mounted() {
    this.$emit("changeComponent", 'profile');
    this.getUserRequest();
  },
  data() {
    return {
      userRequest: {},
      changePassword: {
        password: '',
        passwordConfirm: ''
      },
      showDiv: false,
      isUsernameValid: true,
      isEmailValid: true,
      isEmailFree: true,
      isOldPasswordValid: true,
      isOldPasswordEmpty: false,
      isPasswordValid: true,
      isPassword2Valid: true
    }
  },
  methods: {
    editFields() {
      $('#first-name').prop('disabled', false);
      $('#last-name').prop('disabled', false);
      $('#email').prop('disabled', false);

      $('#edit').css('display', 'none');
      $('#showChangePassword').css('display', 'none');
      $('#submit-edit').css('display', 'block');
      $('#cancel-edit').css('display', 'block');
    },

    disableInputs() {
      this.isEmailValid = true;
      this.isEmailFree = true;

      $('#first-name').prop('disabled', true);
      $('#last-name').prop('disabled', true);
      $('#email').prop('disabled', true);

      $('#edit').css('display', 'block');
      $('#showChangePassword').css('display', 'block');
      $('#submit-edit').css('display', 'none');
      $('#cancel-edit').css('display', 'none');
    },

    editRequest() {
      this.isEmailValid = ui.methods.checkEmail(this.userRequest.email);
      if (this.isEmailValid) {
        this.sendEditRequest()
      } else {
        this.createToast(this.$t("registration.enterEmail"), "error");
      }
    },

    cancelRequest() {
      this.getUserRequest();
      this.disableInputs();
    },

    showChangePasswordForm() {
      $('#edit').css('display', 'none');
      $('#showChangePassword').css('display', 'none');
      $('.lineBody-r').css('display', 'block');
    },

    closeChangePasswordForm() {
      this.isOldPasswordValid = true;
      this.isPasswordValid = true;
      this.isPassword2Valid = true;

      this.changePassword.oldPassword = '';
      this.changePassword.password = '';
      this.changePassword.passwordConfirm = '';

      $('#edit').css('display', 'block');
      $('#showChangePassword').css('display', 'block');
      $('.lineBody-r').css('display', 'none');
      $('#oldPassword').val('').attr("type", "password");
      $('#newPassword').val('').attr("type", "password");
      $('#newPasswordConfirm').val('').attr("type", "password");
    },

    getUserRequest() {
      const headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getUserByIdUrl(window.localStorage.getItem("id")),
          {headers}
      ).then(res => {
          this.userRequest = res.data;
      }).catch(() =>
          this.createToast(this.$t("userProfile.userNotFound"), "error")
      );
    },

    saveNewPassword() {
      this.isOldPasswordEmpty = ui.methods.isEmptyString(this.changePassword.oldPassword);
      this.isOldPasswordValid = !this.isOldPasswordEmpty;
      this.isPasswordValid = (this.changePassword.password != null && this.changePassword.password.trim().length > 5);
      this.isPassword2Valid = (this.changePassword.password === this.changePassword.passwordConfirm)

      if (this.isOldPasswordValid && this.isPasswordValid && this.isPassword2Valid) {
        const headers = Link.methods.getHeaders();
        const req = {
          oldPassword: this.changePassword.oldPassword,
          userName: this.userRequest.username,
          password: this.changePassword.password
        }
        axios.put(
            Link.methods.getUserChangePasswordUrl(),
            req,
            {headers}
        ).then(() => {
            this.createToast(this.$t("userProfile.passwordEditToast"), "success");

            let request = {
              username: window.localStorage.getItem('userName'),
              password: this.changePassword.password
            }
            axios.post(
                Link.methods.getAuthenticationUrl(),
                request
            ).then((res) => {
              window.localStorage.setItem('token', `Bearer_` + res.data.token);
              this.closeChangePasswordForm()
            }).catch(() => {
              this.createToast(this.$t("userForm.authErrorToast"), "error");
            });
        }).catch((reason) => {
          if (reason.response.status === 403) {
            this.isOldPasswordValid = false;
            this.createToast(this.$t("userProfile.wrongPasswordError"), "error");
          } else {
            this.createToast(this.$t("userProfile.changePasswordError"), "error");
          }
        });
      }
    },

    sendEditRequest() {
      const headers = Link.methods.getHeaders();
      return axios.put(
          Link.methods.getUserUpdateUrl(),
          this.userRequest,
          {headers}
      ).then((res) => {
        this.$emit("updateUserInfo");

        this.isEmailFree = true;
        this.disableInputs();

        this.createToast(this.$t("userProfile.profileEditToast"), "success");
        this.userRequest = res.data;
      }).catch((reason) => {
         if (reason.response.status === 409) {
           // email already exists
           this.isEmailFree = false;
           this.isEmailValid = false;
           this.createToast(this.$t("userForm.emailAlreadyExistsToast", [this.userRequest.email]), "error");
         } else {
           this.createToast(this.$t("userProfile.profileNotEditToast"), "error")
         }
      });
    },

    showOldPassword() {
      let raw = document.getElementById("oldPassword");
      if (raw.type === "password") {
        raw.type = "text";
      } else {
        raw.type = "password";
      }
    },

    showNewPassword() {
      let raw = document.getElementById("newPassword");
      if (raw.type === "password") {
        raw.type = "text";
      } else {
        raw.type = "password";
      }
    },

    showNewPassword2() {
      let raw = document.getElementById("newPasswordConfirm");
      if (raw.type === "password") {
        raw.type = "text";
      } else {
        raw.type = "password";
      }
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    }
  }
}


</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import '../../public/materialize-src/sass/materialize.scss';
@import "../../public/styles/singleUser";

</style>

