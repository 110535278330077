<template>
  <div class="box">
    <div class="title">
      <div class="u-name">
        <div v-if="this.$route.path!==('/profile')" id="back" class="fa fa-angle-left" @click="goBack"></div>
        <span class="name">{{ userRequest.username }}</span>
      </div>
    </div>
    <div class="body">
      <div class="lineBody-l">
        <form autocomplete="off">
          <div class="input-field col">
            <label v-if="!isUsernameValid" for="user-name" class="active invalid_value">
              {{ $t(isUsernameFree ? "userForm.enterUsername" : "registration.usernameAlreadyExists") }}
            </label>
            <label v-else for="user-name" class="active">
              {{ $t(isNewUser() ? "registration.userName" : "userForm.userName") }}
            </label>
            <input id="user-name" type="text" class="validate" v-model="userRequest.username" disabled>
          </div>

          <div class="input-field col">
            <input id="first-name" type="text" class="validate" v-model="userRequest.firstName" disabled>
            <label for="first-name" class="active">{{ $t("userForm.firstName") }}</label>
          </div>

          <div class="input-field col">
            <input id="last-name" type="text" class="validate" v-model="userRequest.lastName" disabled>
            <label for="last-name" class="active">{{ $t("userForm.lastName") }}</label>
          </div>

          <div class="input-field col">
            <label v-if="!isEmailValid" for="email" class="active invalid_value">
              {{ $t(isEmailFree ? "registration.enterEmail" : "userForm.emailAlreadyExists") }}
            </label>
            <label v-else for="email" class="active">
              {{ $t(isNewUser() ? "registration.email" : "userForm.email") }}
            </label>
            <input id="email" type="text" class="validate" v-model="userRequest.email" disabled>
          </div>

          <div class="input-field col" v-if="isNewUser()">
            <label v-if="!isPasswordValid" for="password" class="active invalid_value">
              {{ $t("userForm.enterPassword") }}
            </label>
            <label v-else for="password" class="active">
              {{ $t(isNewUser() ? "registration.password" : "userForm.password") }}
            </label>
            <input id="password" type="password" class="validate" v-model="userRequest.password" disabled>
            <i class="fa fa-eye right" @click="showPassword"></i>
          </div>

          <div class="input-field col" v-if="!(this.$route.path===('/profile'))">
            <select id="role" v-model="userRequest.role" disabled>
              <option value="ADMIN">{{ $t("userForm.roleAdmin") }}</option>
              <option value="USER">{{ $t("userForm.roleUser") }}</option>
              <option value="GUEST">{{ $t("userForm.roleGuest") }}</option>
            </select>
            <label class="active">{{ $t("userForm.role") }}</label>
          </div>

          <div id="controller">
            <button v-on:click="editFields()" class="btn waves-effect waves-light" type="button" id="edit">
              {{ $t("userForm.editProfile") }}<i class="material-icons right">edit</i>
            </button>
            <button v-on:click="editRequest()" class="btn waves-effect waves-light" type="button" id="submit-edit">
              {{ $t("userForm.submit") }}<i class="material-icons right">done</i>
            </button>
            <button v-on:click="createRequest()" class="btn waves-effect waves-light" type="button" id="create">
              {{ $t("userForm.create") }}<i class="fa fa-plus right"></i>
            </button>
            <button v-on:click="cancelRequest()"
                    class="btn waves-effect waves-light"
                    type="button"
                    id="cancel-edit">
              {{ $t("userForm.cancel") }}<i class="material-icons right">cancel</i>
            </button>
            <button v-on:click="showChangePasswordForm()"
                    class="btn waves-effect waves-light"
                    type="button"
                    id="showChangePassword">
              {{ $t("userForm.changePassword") }}<i class="material-icons right">edit</i>
            </button>
          </div>
        </form>
      </div>
      <div class="lineBody-r">
        <form autocomplete="off">
          <div class="input-field col" v-if="this.$route.path===('/profile')">
            <label v-if="!isOldPasswordValid" for="oldPassword" class="active invalid_value">
              {{ $t(isOldPasswordEmpty ? "userForm.emptyOldPassword" : "userForm.wrongPassword") }}
            </label>
            <label v-else for="oldPassword" class="active">
              {{ $t("userForm.oldPassword") }}
            </label>
            <input id="oldPassword" type="password" class="validate" v-model="changePassword.oldPassword">
            <i class="fa fa-eye right" @click="showOldPassword"></i>
          </div>

          <div class="input-field col">
            <label v-if="!isPasswordValid" for="newPassword" class="active invalid_value">
              {{ $t("userForm.enterNewPassword") }}
            </label>
            <label v-else for="newPassword" class="active">
              {{ $t("userForm.newPassword") }}
            </label>
            <input id="newPassword" type="password" class="validate" v-model="changePassword.password">
            <i class="fa fa-eye right" @click="showNewPassword"></i>
          </div>

          <div class="input-field col">
            <label v-if="!isPassword2Valid" for="newPasswordConfirm" class="active invalid_value">
              {{ $t("userForm.passwordsAreNotEqual") }}
            </label>
            <label v-else for="newPasswordConfirm" class="active">
              {{ $t("userForm.newPasswordConfirm") }}
            </label>
            <input id="newPasswordConfirm" type="password" class="validate" v-model="changePassword.passwordConfirm">
            <i class="fa fa-eye right" @click="showNewPassword2"></i>
          </div>

          <button v-on:click="saveNewPassword()" class="btn waves-effect waves-light" type="button" id="savePassword">
            {{ $t("userForm.submitPassword") }}
            <i class="material-icons right">done</i>
          </button>

          <button v-on:click="closeChangePasswordForm()"
                  class="btn waves-effect waves-light"
                  type="button"
                  id="cancelChangePassword">
            {{ $t("userForm.cancel") }}<i class="material-icons right">cancel</i>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserForm",
  methods: {
    editFields() {
    },

    editRequest() {
    },

    createRequest() {
    },

    cancelRequest() {
    },

    goBack() {
    },

    saveNewPassword() {
    },

    showChangePasswordForm() {
    },

    closeChangePasswordForm() {
    },

    showOldPassword() {
    },

    showPassword() {
    },

    showNewPassword() {
    },

    showNewPassword2() {
    },

    isNewUser() {
       return false;
    }
  }
}
</script>

<style scoped lang="scss">
</style>